import React from 'react';
import { Shield, Lock, UserCircle, FileText, ExternalLink } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const GDPRSection = () => {

    const { t } = useTranslation();

  return (
    <div className="relative max-w-6xl mx-auto mb-24">
      {/* Background Effects */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -top-40 -left-40 w-80 h-80 rounded-full mix-blend-multiply filter blur-xl opacity-30 animate-blob" />
        <div className="absolute -bottom-40 -right-40 w-80 h-80 rounded-full mix-blend-multiply filter blur-xl opacity-30 animate-blob animation-delay-2000" />
      </div>

      {/* Content Container */}
      <div className="relative bg-white rounded-3xl shadow-2xl p-8 border border-gray-100">
        {/* Header with GDPR Logo */}
        <div className="flex flex-col sm:flex-row items-center justify-between mb-12">
          <div className="flex items-center mb-6 sm:mb-0">
            {/* GDPR Shield Logo */}
            <div className="mr-6 bg-gradient-to-br from-red-500 to-red-600 p-4 rounded-2xl shadow-lg transform -rotate-6 hover:rotate-0 transition-all duration-300">
              <Shield className="w-8 h-8 text-white" />
            </div>
            <div>
              <h2 className="text-3xl font-bold bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent">
                {t('gdprCompliant')}
              </h2>
              <p className="text-gray-600">{t('yourPricavy')}</p>
            </div>
          </div>
          <a 
            href="https://drive.google.com/file/d/1YBFUR6iIhu-v2t83AvJLdngwKhRhg7ec/view?usp=drive_link"
            className="group flex items-center px-6 py-3 bg-gray-50 hover:bg-red-50 rounded-xl text-gray-600 hover:text-red-600 transition-colors duration-300"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FileText className="w-5 h-5 mr-2" />
            <span className="font-medium">{t('PrivacyPolicy')}</span>
            <ExternalLink className="w-4 h-4 ml-2 opacity-50 group-hover:opacity-100" />
          </a>
        </div>

        {/* Main content */}
        <p className="text-gray-600 text-lg max-w-3xl mx-auto text-center mb-12">
            {t('atBloodFlow')}
        </p>

        {/* Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Data Protection */}
          <div className="group bg-gradient-to-br from-white to-gray-50 p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300">
            <div className="bg-red-100 group-hover:bg-red-500 p-3 rounded-xl w-fit mb-6 transition-colors duration-300">
              <Lock className="w-6 h-6 text-red-500 group-hover:text-white transition-colors duration-300" />
            </div>
            <h3 className="text-xl font-bold text-gray-900 mb-4">{t('dataProt')}</h3>
            <p className="text-gray-600">
                {t('yourData')}
            </p>
          </div>

          {/* User Control */}
          <div className="group bg-gradient-to-br from-white to-gray-50 p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300">
            <div className="bg-red-100 group-hover:bg-red-500 p-3 rounded-xl w-fit mb-6 transition-colors duration-300">
              <UserCircle className="w-6 h-6 text-red-500 group-hover:text-white transition-colors duration-300" />
            </div>
            <h3 className="text-xl font-bold text-gray-900 mb-4">{t('userControl')}</h3>
            <p className="text-gray-600">
                {t('fullControl')}
            </p>
          </div>

          {/* Transparent Processing */}
          <div className="group bg-gradient-to-br from-white to-gray-50 p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300">
            <div className="bg-red-100 group-hover:bg-red-500 p-3 rounded-xl w-fit mb-6 transition-colors duration-300">
              <FileText className="w-6 h-6 text-red-500 group-hover:text-white transition-colors duration-300" />
            </div>
            <h3 className="text-xl font-bold text-gray-900 mb-4">{t('transparentProc')}</h3>
            <p className="text-gray-600">
                {t('clearDoc')}
            </p>
          </div>
        </div>

        {/* Bottom Badge */}
        <div className="mt-12 flex justify-center">
          <div className="inline-flex items-center px-4 py-2 bg-gray-50 rounded-full text-sm text-gray-600">
            <Shield className="w-4 h-4 text-red-500 mr-2" />
                {t('yourDataIsPro')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GDPRSection;