import React from "react"

export function ArcadeEmbed() {
    return (
      <div style={{
         position: 'relative',
          paddingBottom: 'calc(52.361111111111114% + 41px)',
          height: 0,
          width: '80%',
          margin: '0 auto'
        }}>
        <iframe
          src="https://demo.arcade.software/iDmJ2dDgco05JN4MyHEb?embed&embed_mobile=inline&embed_desktop=inline&show_copy_link=true"
          title="Access Your Personalized Health Insights"
          frameBorder="0"
          loading="lazy"
          allowFullScreen
          allow="clipboard-write"
          style={{
             position: 'absolute',
             top: 0,
             left: 0, 
             width: '100%', 
             height: '85%', 
             colorScheme: 'light' }}
        />
      </div>
    )
  }
  